import { Component, OnInit } from '@angular/core';
import { ICategoryModel } from './models/ICategoryModel';

import categories from './models/categories.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  public sex = 'f';
  public name = '';
  public grade: number = null;
  public gradeText: string = null;

  public categories = new Map<string, ICategoryModel>();
  private result: number = null;

  ngOnInit(): void {
    categories.forEach((t) => {
      const id = t.id;
      delete t.id;
      this.categories.set(id, t);
    });

    this.updateResultAndGrade();
    this.gradeText = this.generateGradeText();
  }

  public getCategoryResult(id: string): number {
    return this.categories.get(id).value;
  }

  public handleCategoryValueChange(event: InputEvent): void {
    const value = (event.target as any).value as string;
    const id = (event.target as any).id as string;

    this.setCategoryValue(id, value);
    this.updateResultAndGrade();
    this.gradeText = this.generateGradeText();
  }

  public handleSexSelectionChange(event: InputEvent): void {
    this.sex = (event as any).value as string;
    this.gradeText = this.generateGradeText();
  }

  public handleNameChange(): void {
    this.gradeText = this.generateGradeText();
  }

  private generateGradeText(): string {
    const textTemplates: string[] = [];

    for (const item of this.categories.entries()) {
      const level = this.categoryResultToCategoryLevel(item[0], item[1].value);
      textTemplates.push(item[1].textTemplates[level]);
    }

    textTemplates.push(
      'Die Arbeit wird mit der Note $grade bewertet.'.replace(
        '$grade',
        this.grade.toString()
      )
    );

    return textTemplates
      .join('. ')
      .replace(/\$name/g, this.getSalutation(this.sex) + ' ' + this.name);
  }

  private getSalutation(sex: string): string {
    switch (sex) {
      case 'f':
        return 'Frau';

      case 'm':
        return 'Herr';
    }
  }

  private categoryResultToCategoryLevel(
    id: string,
    categoryResult: number
  ): string {
    switch (id) {
      case 'A':
        if (categoryResult >= 0 && categoryResult <= 3) {
          return 'D';
        }
        if (categoryResult >= 4 && categoryResult <= 7) {
          return 'C';
        }
        if (categoryResult >= 8 && categoryResult <= 11) {
          return 'B';
        }
        if (categoryResult >= 12 && categoryResult <= 15) {
          return 'A';
        }
        break;

      case 'B':
        if (categoryResult >= 0 && categoryResult <= 3) {
          return 'D';
        }
        if (categoryResult >= 4 && categoryResult <= 7) {
          return 'C';
        }
        if (categoryResult >= 8 && categoryResult <= 11) {
          return 'B';
        }
        if (categoryResult >= 12 && categoryResult <= 15) {
          return 'A';
        }
        break;

      case 'C':
        if (categoryResult >= 0 && categoryResult <= 5) {
          return 'D';
        }
        if (categoryResult >= 6 && categoryResult <= 10) {
          return 'C';
        }
        if (categoryResult >= 11 && categoryResult <= 15) {
          return 'B';
        }
        if (categoryResult >= 16 && categoryResult <= 20) {
          return 'A';
        }
        break;

      case 'D':
        if (categoryResult >= 0 && categoryResult <= 10) {
          return 'D';
        }
        if (categoryResult >= 11 && categoryResult <= 17) {
          return 'C';
        }
        if (categoryResult >= 18 && categoryResult <= 25) {
          return 'B';
        }
        if (categoryResult >= 26 && categoryResult <= 30) {
          return 'A';
        }
        break;

      case 'E':
        if (categoryResult >= 0 && categoryResult <= 5) {
          return 'D';
        }
        if (categoryResult >= 6 && categoryResult <= 10) {
          return 'C';
        }
        if (categoryResult >= 11 && categoryResult <= 15) {
          return 'B';
        }
        if (categoryResult >= 16 && categoryResult <= 20) {
          return 'A';
        }
        break;

      default:
        break;
    }
  }

  private setCategoryValue(id: string, value: string): void {
    const currentValue = this.categories.get(id);
    this.categories.set(id, {
      ...currentValue,
      value: Number.parseInt(value, 10),
    });
  }

  private updateResultAndGrade(): void {
    this.result =
      this.getCategoryResult('A') +
      this.getCategoryResult('B') +
      this.getCategoryResult('C') +
      this.getCategoryResult('D') +
      this.getCategoryResult('E');

    this.grade = this.calculateGrade();
  }

  private calculateGrade(): number {
    if (this.result >= 38 && this.result <= 43) {
      return 4.0;
    }

    if (this.result >= 44 && this.result <= 49) {
      return 3.7;
    }

    if (this.result >= 50 && this.result <= 55) {
      return 3.3;
    }

    if (this.result >= 56 && this.result <= 61) {
      return 3.0;
    }

    if (this.result >= 62 && this.result <= 67) {
      return 2.7;
    }

    if (this.result >= 68 && this.result <= 73) {
      return 2.3;
    }

    if (this.result >= 74 && this.result <= 79) {
      return 2.0;
    }

    if (this.result >= 80 && this.result <= 85) {
      return 1.7;
    }

    if (this.result >= 86 && this.result <= 92) {
      return 1.3;
    }

    if (this.result >= 93 && this.result <= 100) {
      return 1.0;
    }
  }
}
