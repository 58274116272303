<div class="wrapper">
  <form>
    <mat-card>
      <mat-grid-list cols="2" rowHeight="60px">

        <mat-grid-tile colspan="2">
          <h1>Bewertung</h1>
        </mat-grid-tile>

        <mat-grid-tile class="text-left">Geschlecht</mat-grid-tile>
        <mat-grid-tile>
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button name="sex" value="f" [checked]="sex === 'f'" (change)="handleSexSelectionChange($event)">
              Weiblich</mat-radio-button>
            <mat-radio-button name="sex" value="m" [checked]="sex === 'm'" (change)="handleSexSelectionChange($event)">
              Mänlich</mat-radio-button>
          </mat-radio-group>
        </mat-grid-tile>

        <mat-grid-tile>Name</mat-grid-tile>
        <mat-grid-tile>
          <mat-form-field class="example-full-width">
            <mat-label>Name des Prüflings</mat-label>
            <input matInput name="name" [(ngModel)]="name" (change)="handleNameChange()">
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card>

    <mat-card class="spacer">
      <mat-grid-list cols="2" rowHeight="60px">

        <mat-grid-tile colspan="2">
          <h1>Kriterien</h1>
        </mat-grid-tile>

        <ng-container *ngFor="let item of categories | keyvalue">
          <mat-grid-tile>
            {{item.value.heading}}
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field class="example-full-width">
              <mat-label>Erreichtes Ergebnis</mat-label>
              <input matInput id={{item.key}} name={{item.key}} type="number" min={{item.value.min}}
                max={{item.value.max}} placeholder="0" [value]="item.value.value"
                (change)="handleCategoryValueChange($event)">
            </mat-form-field>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </mat-card>

    <mat-card class="spacer">
      <h1>Begründung</h1>
      {{gradeText}}
    </mat-card>
  </form>
</div>